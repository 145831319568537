/* eslint-disable camelcase */
import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useCookies } from 'react-cookie'
import parse from 'html-react-parser'
import * as styles from './style.module.scss'

const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(['gatsby-gdpr-google-analytics', 'gatsby-gdpr-linkedin'])

  const current = new Date()
  const nextYear = new Date()
  nextYear.setFullYear(current.getFullYear() + 1)

  const [consent, setConsent] = useState(true)
  const {
    prismicCookieBanner: {
      data: { cta_text, description, statistics_checkbox, statistics_description },
    },
  } = useStaticQuery(graphql`
    query CookieBannerQuery {
      prismicCookieBanner {
        data {
          cta_text
          description {
            html
          }
          statistics_checkbox
          statistics_description {
            html
          }
        }
      }
    }
  `)

  return (
    <section className={styles.banner}>
      <div className={styles.bannerContainer}>
        <div className={styles.main}>
          <div className={styles.text}>{parse(description.html)}</div>
          <button
            className={styles.cta}
            type="button"
            onClick={() => { setCookie('gatsby-gdpr-google-analytics', consent, { path: '/', expires: nextYear });setCookie('gatsby-gdpr-linkedin', consent, { path: '/', expires: nextYear }) }}
          >
            {cta_text}
          </button>
        </div>
        <div className={styles.checkContainer}>
          <input
            type="checkbox"
            id="statistics"
            className={styles.checkbox}
            checked={consent}
            onChange={e => setConsent(e.target.checked)}
          />
          <label htmlFor="statistics" className={styles.checkboxLabel}>
            {statistics_checkbox}
          </label>

          <div className={styles.statisticsText}>{parse(statistics_description.html)}</div>
        </div>
      </div>
    </section>
  )
}

export default CookieBanner

// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--2kwZ-";
export var ctBlack = "style-module--ctBlack--2Z_-w";
export var ctSmall = "style-module--ctSmall--3lHWw";
export var ctMedium = "style-module--ctMedium--2oR4n";
export var ctLarge = "style-module--ctLarge--W7RKp";
export var ctXLarge = "style-module--ctXLarge--33CKk";
export var ctIntro = "style-module--ctIntro--1jGuF";
export var ctBody = "style-module--ctBody--2aKbK";
export var text = "style-module--text--2de2i";
export var ctBodyMedium = "style-module--ctBodyMedium--2j41f";
export var ctBodyBold = "style-module--ctBodyBold--zS1ix";
export var ctLink = "style-module--ctLink--hoO2m";
export var cta = "style-module--cta--1UsZ_";
export var frutiLarge = "style-module--frutiLarge--3mTqn";
export var ctUi = "style-module--ctUi--1c8K4";
export var ctMeta = "style-module--ctMeta--3FC6F";
export var ctMicro = "style-module--ctMicro--1pLXb";
export var statisticsText = "style-module--statisticsText--2VJ18";
export var checkboxLabel = "style-module--checkboxLabel--1LA5w";
export var ctMediumItalic = "style-module--ctMediumItalic--Q8qVK";
export var banner = "style-module--banner--2UeAz";
export var bannerContainer = "style-module--bannerContainer--33ihe";
export var main = "style-module--main--nqxpm";
export var checkContainer = "style-module--checkContainer--1lTjC";
export var checkbox = "style-module--checkbox--1_iY9";
// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-archive-index-jsx": () => import("./../../../src/pages/archive/index.jsx" /* webpackChunkName: "component---src-pages-archive-index-jsx" */),
  "component---src-pages-imprint-index-jsx": () => import("./../../../src/pages/imprint/index.jsx" /* webpackChunkName: "component---src-pages-imprint-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-subscribe-index-jsx": () => import("./../../../src/pages/subscribe/index.jsx" /* webpackChunkName: "component---src-pages-subscribe-index-jsx" */),
  "component---src-templates-article-index-jsx": () => import("./../../../src/templates/article/index.jsx" /* webpackChunkName: "component---src-templates-article-index-jsx" */),
  "component---src-templates-format-index-jsx": () => import("./../../../src/templates/format/index.jsx" /* webpackChunkName: "component---src-templates-format-index-jsx" */),
  "component---src-templates-topic-index-jsx": () => import("./../../../src/templates/topic/index.jsx" /* webpackChunkName: "component---src-templates-topic-index-jsx" */)
}


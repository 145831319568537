import './src/styles/global.scss'

/* eslint-disable react/jsx-filename-extension */
import * as React from 'react'
// import { PreviewStoreProvider } from 'gatsby-source-prismic'
import { CookiesProvider } from 'react-cookie'
import { divide } from 'lodash'

export const wrapRootElement = ({ element }) => (
  <div>
    <CookiesProvider>{element}</CookiesProvider>
  </div>
)

export default wrapRootElement

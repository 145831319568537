/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import uniqid from 'uniqid'
import classnames from 'classnames'
import parse from 'html-react-parser'
import { useMediaQuery } from 'react-responsive'
import * as styles from './style.module.scss'
import Logo from './MEDTECH_PULSE_logo.svg'
// import LogoParticles from './LogoParticles'
import Container from '~components/globals/Container'

const Menu = ({ location }) => {
  const [isShrinked, setIsShrinked] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef(null)

  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 766px)' })

  const toggleButton = () => {
    if (isOpen) {
      document.querySelector('body').style.overflow = 'auto'
    } else {
      document.querySelector('body').style.overflow = 'hidden'
    }
    setIsOpen(!isOpen)
  }

  const handleScroll = () => {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      setIsShrinked(true)
    } else {
      setIsShrinked(false)
    }
  }

  const handleRedirection = () => {
    const links = menuRef.current.querySelectorAll('a')

    Array.from(links).forEach(link => {
      link.addEventListener('click', () => {
        setIsOpen(false)
      })
    })
  }

  useEffect(() => {
    handleRedirection()
    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const { allPrismicTopicPage, allPrismicPage, prismicMenu } = useStaticQuery(graphql`
    query MenuQuery {
      allPrismicPage {
        edges {
          node {
            data {
              slug
              navigation_name {
                text
              }
            }
            prismicId
          }
        }
      }
      allPrismicTopicPage {
        nodes {
          data {
            navigation_name {
              text
            }
            topic {
              id
              slug
            }
          }
          prismicId
        }
      }
      prismicMenu {
        data {
          formats {
            format {
              id
            }
          }
          formats_label {
            text
          }
          logo_image {
            alt
            url
          }
          newsletter {
            page {
              id
            }
          }
          newsletter_label {
            text
          }
          other {
            page {
              id
            }
          }
          other_label {
            text
          }
          suscribe_cta {
            html
          }
          topics {
            topic {
              id
            }
          }
          topics_label {
            text
          }
        }
      }
    }
  `)

  const {
    formats,
    formats_label,
    logo_image,
    newsletter,
    newsletter_label,
    other,
    other_label,
    suscribe_cta,
    topics,
    topics_label,
  } = prismicMenu.data

  const pages = allPrismicPage.edges
  const menuData = prismicMenu.data
  const allTopics = allPrismicTopicPage.nodes

  const menuClasses = classnames(styles.menu, { [styles.isShrinked]: isShrinked }, { [styles.isOpen]: isOpen })

  return (
    <div ref={menuRef} className={menuClasses}>
      <Container>
        <div className={styles.wrapLeft}>
          <button
            onClick={toggleButton}
            type="button"
            className={styles.burgerWrap}
            aria-label="Toggle menu"
          >
            <div className={styles.burger}>
              <span />
              <span />
              <span />
            </div>
          </button>
          <Link to="/" className={styles.logoContainer}>
            {/* <img src={menuData.logo_image.url} alt={menuData.logo_image.url} /> */}
            <img src={Logo} alt={menuData.logo_image.url} />
          </Link>
        </div>
        {isDesktop && (
          <Link to="/subscribe" className={styles.subscribeButton}>
            <span>
              Subscribe
            </span>
          </Link>
        )}
        <div className={styles.menuContent}>
          {isMobile && (
            <div className={styles.ctaContainer}>
              <Link to="/subscribe" className={styles.subscribeButton}>
                <span>
                  Subscribe
                </span>
              </Link>
            </div>
          )}
          <Container>
            <nav className={styles.grid}>
              <ul>
                <li className={styles.title}>
                  {formats_label.text}
                </li>
                {formats && formats.map(({ format }) => {
                  const pageLink = pages.filter(item => item.node.prismicId === format.id)
                  return (
                    <li key={uniqid()}>
                      <Link to={`/${pageLink[0].node.data.slug}`}>
                        {pageLink[0].node.data.navigation_name.text}
                      </Link>
                    </li>
                  )
                })}
              </ul>
              <ul>
                <li className={styles.title}>
                  {topics_label.text}
                </li>
                {topics && topics.map(({ topic }) => {
                  const pageLink = allTopics.filter(item => item.prismicId === topic.id)
                  return (
                    <li key={uniqid()}>
                      <Link to={`/topic/${pageLink[0].data.topic.slug}`}>
                        {pageLink[0].data.navigation_name.text}
                      </Link>
                    </li>
                  )
                })}
              </ul>
              <ul>
                <li className={styles.title}>
                  {newsletter_label.text}
                </li>
                {newsletter && newsletter.map(({ page }) => {
                  const pageLink = pages.filter(item => item.node.prismicId === page.id)
                  return (
                    <li key={uniqid()}>
                      <Link to={`/${pageLink[0].node.data.slug}`}>
                        {pageLink[0].node.data.navigation_name.text}
                      </Link>
                    </li>
                  )
                })}
              </ul>
              <ul>
                <li className={styles.title}>
                  {other_label.text}
                </li>
                {other && other.map(({ page }) => {
                    const pageLink = pages.filter(item => item.node.prismicId === page.id)
                    return (
                      <li key={uniqid()}>
                        <Link to={`/${pageLink[0].node.data.slug}`}>
                          {pageLink[0].node.data.navigation_name.text}
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </nav>
          </Container>
        </div>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={toggleButton}
          onClick={toggleButton}
          className={styles.overlay}
        >
          Toggle Menu
        </div>
      </Container>
    </div>
  )
}

export default Menu

import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import PropTypes from 'prop-types'
import Menu from '~components/globals/Menu'
import Footer from '~components/globals/Footer'
import CookieBanner from '~components/globals/CookieBanner'
import * as styles from './style.module.scss'

const Layout = ({ children, location }) => {
  const [loaded, setLoaded] = useState(null)
  const [cookies] = useCookies(['gatsby-gdpr-google-analytics'])

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <>
      <div>
        <Menu location={location} />
        <main className={styles.main}>{children}</main>
        <Footer location={location} />
        <div className="bottom-line" />
        {!cookies['gatsby-gdpr-google-analytics'] && loaded && <CookieBanner />}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
}

export default Layout

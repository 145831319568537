/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import uniqid from 'uniqid'
import { RichText } from 'prismic-reactjs'
import parse from 'html-react-parser'
import classnames from 'classnames'
import Container from '~components/globals/Container'
import * as styles from './style.module.scss'

const Footer = ({ location }) => {
  const { allPrismicPage, prismicFooter } = useStaticQuery(graphql`
    query FooterQuery {
      allPrismicPage {
        edges {
          node {
            data {
              slug
              title {
                text
              }
            }
            prismicId
          }
        }
      }
      prismicFooter {
        data {
          ceramtec_link
          ceramtech_logo {
            alt
            url
          }
          ceramtech_presentation {
            html
          }
          description {
            html
          }
          navigation {
            link {
              id
            }
          }
        }
      }
    }
  `)

  const pages = allPrismicPage.edges
  const footerPages = prismicFooter.data.navigation
  const footerData = prismicFooter.data

  const isSubscribePage = location.pathname === '/subscribe' || location.pathname === '/subscribe/'
  const isArticlePage = location.pathname.includes('/article')

  const footerClasses = classnames(styles.footer, {
    [styles.noMargin]: isSubscribePage || isArticlePage,
    [styles.blueBorder]: isSubscribePage,
  })

  const {
    ceramtec_link,
    ceramtech_logo,
    ceramtech_presentation,
    description,
  } = footerData

  return (
    <footer className={footerClasses}>
          <Container>
        <div className={styles.top}>
          <p className={styles.description}>
            {parse(description.html)}
          </p>
          <div className={styles.secondLogo}>
            <p className={styles.secondLogoPresentation}>
              {parse(ceramtech_presentation.html)}
            </p>
            <a href={ceramtec_link} target="_blank" rel="noreferrer">
              <img src={ceramtech_logo.url} alt={ceramtech_logo.alt} />
            </a>
          </div>
        </div>
        <div className={styles.bottom}>
          <nav className={styles.nav}>
            <ul>
              {footerPages &&
                footerPages.map(({ link }) => {
                  const pageLink = pages.filter(item => item.node.prismicId === link.id)
                  return (
                    <li key={uniqid()}>
                      <Link className={styles.link} to={`/${pageLink[0].node.data.slug}`}>
                        {pageLink[0].node.data.slug}
                      </Link>
                    </li>
                  )
                })}
            </ul>
          </nav>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
